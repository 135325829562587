import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import DealerFinder from "../modules/DealerFinder"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"

import useRukoGeodata from "../../hooks/useRukoGeodata"

const DealerFinderBlock = ({ block }) => {
  const { locationData } = useRukoGeodata()

  return (
    <BlockWrapper
      block={block}
      blockPadding=""
      blockWidth="screen"
      showHeadline="false"
    >
      {block.headline && (
        <section className="block-w-container">
          <Headline headline={block.headline} />
        </section>
      )}
      {locationData !== undefined && (
        <DealerFinder
          userLocationCountry={
            locationData && locationData.country && locationData.country.name
              ? locationData.country.name
              : null
          }
        />
      )}
    </BlockWrapper>
  )
}

export default DealerFinderBlock
